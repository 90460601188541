import React from 'react';
import rocketimg from '../assets/levelmechanic.png';

const RocketBody = () => {

    return(
        <>
        <div className="bodytext">
            This is a game written in C# using Unity game engine. It has some cool particle physics going on, and I think it's a bit charming.
            You can play it <a href="https://simmer.io/@Ryanlueds/rocket-man-pew-pew">here</a>.
        </div>
        <div className="bodytext">
            <img src={rocketimg} alt="Imagine a rocket..."/>
        </div>
        <></>
        </>
    )
}

export default RocketBody