import React from 'react';
import logo1 from '../assets/lueder-cat-1.png';
import logo2 from '../assets/lueder-cat-2.png';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    
    let navigate = useNavigate();

    const logos = [logo1, logo2];
    const logo = logos[Math.floor(Math.random() * logos.length)];

    return(
        <>
            <img src={logo} alt="Imagine a cat..."/>
            <button className="button-59" onClick={() => navigate("/")}>Home</button>
            <button className="button-59" onClick={() => navigate("/nano_mamba")}>nano-mamba</button>
            <button className="button-59" onClick={() => navigate("/lueder_computer")}>Lueder Computer</button>
            <button className="button-59" onClick={() => navigate("/dansu")}>Dansu</button>
            <button className="button-59" onClick={() => navigate("/sudoku_solver")}>Sudoku Solver</button>
            <button className="button-59" onClick={() => navigate("/rocketman")}>Rocketman</button>
        </>
    );
}

export default Navbar