import React from 'react';

const MambaBody = () => {

    return(
        <>
        <div className="bodytext">
            I wrote an incredibly simplified and abridged implementation of Mamba, "nano-mamba". You can read through the notebook <a href="https://colab.research.google.com/drive/13QEM6f0mN0ZaqTrppVDv0DcxwDHG8piq?usp=sharing">here</a>.
            The output is a bit nonsensical, but all the metrics thus far show that the model is learning. I plan to scale the model up massively (it's only trained on 40k lines of Shakespeare), but for now,
            enjoy silly model garbage:  
        </div>
        <div className="bodytext">
            Hello my name is my be, what now,
            But it not in to no a mine;
            And I my your not the will in'd.
        </div>
        <div className="bodytext">
            ROM lady?
        </div>
        <div className="bodytext">
            KINGUS:
            Where me.
        </div>
        <div className="bodytext">
            head daily:
            That I my
        </div>

        </>
    )
}

export default MambaBody