import React from 'react';


const ComputerBody = () => {

    return(
        <>
        <div className="bodytext">
            LuederComputer is a moderately strong chess engine written in C. It is evaluated to be around 2000 elo strength on Lichess.
            Don't tell anyone about that last detail because this is against Lichess TOS. If Google can do it for their <a href="https://arxiv.org/pdf/2402.04494">chess engine</a>, I can too. 
        </div>
        <div className="bodytext">
            It only operates through a CLI interface. I could easily implement support for UCI (a standard protocol for chess engines to play with each other), but
            most GUIs for engine versus engine combat is not on MacOS. Also, if it ain't broke, why fix it.
        </div>
        <div className="bodytext">
            Currently, LuederComputer doesn't support opening books, time management algorithms, endgame tables, or multi-threading.
            Some of these features may or may not be implemented in the future.
            Here is what it does support:
        </div>
        <div className="bodytext">
            <b>Move Generation</b>
            <ul>
                <li>Bitboard representation</li>
                <li>Pre-calculated attack tables</li>
                <li>Magic bitboards</li>
                <li>Pseudo-legal move generation</li>
                <li>Make-unmake approach</li>
            </ul>
            <b>Search</b>
            <ul>
                <li>PeSTO's Evaluation Function</li>
                <li>Negamax search</li>
                <li>Quiescense search (integrated in main search)</li>
                <li>Iterative deepening</li>
                <li>Pseudo-legal move generation</li>
                <li>Make-unmake approach</li>
                <li>PV-Move, MVV-LVA, Killer Heuristic Move orderings</li>
                <li>Updated!! Stockfish NNUE</li>
            </ul>
        </div>
        <div className="bodytext">
            The primary motivation behind developing LuederComputer was to itersect a few of my key interests: chess and artificial intelligence.
            Furthermore, I wanted to challenge myself with a complex, long-term endeavor—strongly inspired by my brother
            who, at the time, devoted himself entirely to medical school.
        </div>
        <div className="bodytext">
            When I started developing this chess engine, I expectedly did not know where to begin. I pulled inspiration for the underlying architecture
            of LuederComputer from various projects and pseudo-code from Chess Programming Wiki. Benchmarking the performance of generating moves
            exposes the main weakness in this chess engine, and that is it is very very slow--and that the underlying architecture for LuederComputer sucks. People on Reddit have had 10x the performance
            on legal move generation compared to my own. 
        </div>
        <div className="bodytext">
            I likely will never touch this project ever again after supporting Stockfish NNUE. All of the algorithms in this engine are largely well-known,
            and while researching various techniques and algorithms are fun and dandy, there isn't much room to do something novel. I have spent a wild amount
            of time developing this, and I'd like to lay her to rest for good. Furthermore, the only free gains in performance I can see is from messing around
            with move generation and bitboards which is a real pain. 
        </div>
        </>
    )
}

export default ComputerBody