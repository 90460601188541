import React from 'react';
import sudokugif from "../assets/sudokusolving.gif";


const SudokuBody = () => {

    return(
        <>
        <div className="bodytext">
            At the start of the pandemic, I got really into Sudoku puzzles--namely variant Sudoku. I watched an absurd amount of 
            Cracking the Cryptic on YouTube, and I figured, I'll just make a Sudoku solver that will teach me how to solve some puzzles.
        </div>
        <div className="bodytext">
            The important thing to notice about this solver is that it uses "human" logic to place digits. It's actually
            quite simple to make a Sudoku solver using recursive backtracking, but that only knows a digit is correctly placed
            at the very end. I wanted to make something that will place digits on the board like a human would, using human techniques.
        </div>
        <div className="bodytext">
            <img src={sudokugif} alt="Imagine a sudoku..." />
        </div>
        <div className="bodytext">
            This project was just a matter of implementing key solving techniques, and having them done quickly. It is actually
            pretty difficult to search for some of these patterns (on the order of magnitude of 9<sup>6</sup>).
        </div>
        <div className="bodytext">
            <b>Solution Techniques</b>
            <ul>
                <li>Naked Single, Hidden Single</li>
                <li>Naked Pair, Hidden Pair, Pointing Pairs</li>
                <li>Naked Triple, Naked Quad, Pointing Triples, Hidden Triple, Hidden Quad</li>
                <li>XWing, Swordfish, Jellyfish, XYWing, XYZWing</li>
            </ul>
        </div>
        <div className="bodytext">
            This project was made with Java.
        </div>
        <></>
        </>
    )
}

export default SudokuBody