import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/Home';
import Dansu from './pages/Dansu';
import LuederComputer from './pages/LuederComputer';
import SudokuSolver from './pages/SudokuSolver';
import Rocketman from './pages/Rocketman';
import Mamba from './pages/Mamba';


export function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/nano_mamba" element={<Mamba/>} />
        <Route exact path="/lueder_computer" element={<LuederComputer/>} />
        <Route exact path="/dansu" element={<Dansu />} />
        <Route exact path="/sudoku_solver" element={<SudokuSolver />} />
        <Route exact path="/rocketman" element={<Rocketman />} />
        <Route exact path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
