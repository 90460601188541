import React from 'react';
import Navbar from './Navbar';

const Overlay = (props) => {

    return(
        
        <div className="overlay">
            <div className="mainbody">
                <h2 style={{textAlign: "center", fontWeight: "bolder", textDecoration: "underline"}}> lueder.ryan@gmail.com </h2>
                <div className="column left flexbox">
                    <Navbar />
                </div>
                <div className="column right flexbox" style={{gap: 10}}>
                    {props.page}
                </div>
            </div>
        </div>
        
    )
}

export default Overlay;