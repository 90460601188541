import React from 'react';
import Overlay from '../components/Overlay';
import MambaBody from '../components/MambaBody';

const Mamba = () => {

    return(
        <Overlay 
            page={<MambaBody />}
        />
    );
}

export default Mamba