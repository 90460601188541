import React from 'react';


const randomFact = () => {
    const facts = [
        ["Favorite CSGO Map: ", "Cache"],
        ["Favorite SSBU Character: ", "Captain Falcon"],
        ["Favorite Sauce: ", "Sriracha"],
        ["Shirt: ", "Tucked"],
        ["Favorite Short Story: ", "Tlon, Uqbar, Orbis Tertius"],
        ["The missile knows where it is at all times because: ", "it knows where it isn't"],
        ["Favorite Language: ", "Python"],
        ["Favorite Videogame: ", "Slay the Spire"],
        ["Favorite CSS background-color property: ", "DarkSlateGray"],
        ["Favorite Japanese Particle: ", "を"],
    ];

    return facts[Math.floor(Math.random() * facts.length)];
}

const MainBody = () => {
    const facts = randomFact();

    return(
        <>
        <div className="intro"> <b>Name: </b>Ryan Lueder</div>
        <div className="intro"> <b>School: </b>Georgia Tech</div>
        <div className="intro"> <b>{facts[0]}</b>{facts[1]}</div>
        <div className="bodytext">
            Hello World!
        </div>
        <div className="bodytext">
            I am (at the time of writing) a senior at Georgia Tech. I have a large breadth of hobbies
            and enjoy creating projects centered around them.
        </div>
        <div className="bodytext">
            Glancing at my projects, you can probably tell my favorite hobby is naming things after myself, but following that, I enjoy: chess,
            Sudoku, drawing, Slay the Spire, and many more.
        </div>
        <div className="bodytext">
            I am, at heart, a math guy. Despite having most of my experience as a full-stack developer, I recently switched the concentrations (threads)
            of my major to Intelligence (Artifical Intelligence) because it aligned better with my interests, and I would be able to pursue more math classes.
            I have not regretted this decision and expect more ML-related projects to spring up on this website. 
        </div>
        <div className="bodytext">
            The minimalistic design of this website stems from my fiery hatred towards Javascript and affiliates. Nonetheless, the reader (you) have
            taken some interest towards me. Rest assured, the unprofessional, casual content and architecture of this website is not representative of my other work. I implore you
            to read through my projects and be soothed by the preprocessed, procedurally generated background. Refer to <a href="https://www.youtube.com/watch?v=nTf-O5bnRL4">this</a> to
            see how it's made.
        </div>
        </>
    )
}

export default MainBody