import React from 'react';
import Overlay from '../components/Overlay';
import DansuBody from '../components/DansuBody';

const Dansu = () => {

    return(
        <Overlay 
            page={<DansuBody />}
        />
    );
}

export default Dansu