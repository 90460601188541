import React from 'react';
import Overlay from '../components/Overlay';
import ComputerBody from '../components/ComputerBody';

const LuederComputer = () => {

    return(
        <Overlay 
            page={<ComputerBody />}
        />
    );
}

export default LuederComputer