import React from 'react';
import Overlay from '../components/Overlay';
import SudokuBody from '../components/SudokuBody';

const SudokuSolver = () => {

    return(
        <Overlay 
            page={<SudokuBody />}
        />
    );
}

export default SudokuSolver