import React from 'react';
import Overlay from '../components/Overlay';
import MainBody from '../components/MainBody';

const Home = () => {

    return(
        <Overlay 
            page={<MainBody />}
        />
    );
}

export default Home