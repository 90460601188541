import React from 'react';
import dancegif from '../assets/ryandancing.gif'


const DansuBody = () => {

    return(
        <>
        <div className="bodytext">
            This is a game inspired by Osu. The name Dansu comes from the wasei-eigo term for "Dance", ダンス.
            The thing to appreciate is that you control a set of cursors only using your webcam (how cool!). Here is a demo of me playing it:
        </div>
        <div className="bodytext">
            <img src={dancegif} alt="Imagine a dancing..." />
        </div>
        <div className="bodytext">
            I used a library called MMPose to generate a pose prediction of myself. I then mapped
            the 2D keypoints of my pose to certain regions (head, hands, hips). I use these regions to hit notes.
        </div>
        <div className="bodytext">
            <b>Technology Stack</b>
            <ul>
                <li>OpenMM's MMPose</li>
                <li>PyTorch</li>
                <li>CUDA</li>
                <li>open-cv2</li>
                <li>PyGame</li>
            </ul>
        </div>
        <div className="bodytext">
            The hardest part of this project was definitely to make the inferencing on my pose fast.
            I had to do a lot of configuring with models and datasets, so that we could inference my pose
            every frame and have the game run smoothly.
        </div>
        </>
    )
}

export default DansuBody