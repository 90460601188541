import React from 'react';
import Overlay from '../components/Overlay';
import RocketBody from '../components/RocketBody';

const Rocketman = () => {

    return(
        <Overlay 
            page={<RocketBody />}
        />
    );
}

export default Rocketman